
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import appPaths from '@/config/routing.paths';
import { removeTokens } from '@/core/services/auth.tokens';

export default defineComponent({
	setup() {
		const store = useStore();
		const router = useRouter();

		const { root } = appPaths;
		store.dispatch('axn_updateLoggedIn', false);
		removeTokens();

		router.push({ 
			path: root,
		});
	},
})
